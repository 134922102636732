// extracted by mini-css-extract-plugin
export var h4 = "Contact-module--h4--Lfkyc";
export var p = "Contact-module--p--FXi00";
export var number = "Contact-module--number--x30gB";
export var grey_bg = "Contact-module--grey_bg--f2vYO";
export var button = "Contact-module--button--flsiG";
export var card = "Contact-module--card--N7APn";
export var card_container = "Contact-module--card_container--ASPUH";
export var contact_support = "Contact-module--contact_support--Uyjqu";
export var contact_support_section = "Contact-module--contact_support_section--z68ZV";
export var request_button = "Contact-module--request_button--9n3wb";