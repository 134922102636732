// @flow
import * as React from 'react'
import {Button} from '../../ui-components'

type Props = {
  className: string,
}

export default function ScheduleDemoButton(props: Props) {
  return (
    <Button
      Component="a"
      linkOutProps={{
        url: 'https://insights.opentrons.com/schedule-demo/',
        gtm: {
          category: 'b-hp',
          action: 'click',
          label: 'schedule-demo',
        },
      }}
      className={props.className}
    >
      Schedule demo
    </Button>
  )
}
