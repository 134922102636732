import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import ContactSales from '../components/ContactSales'

const ContactSalesPage = () => (
  <Page>
    <SEO
      title="Contact Sales | Opentrons Lab Automation from $5,000"
      description="Ready for Opentrons in your lab? Labs in 40+ countries trust Opentrons to help automate their workflows. We offer 60-day risk-free returns & flexible payment options. "
    />
    <ContactSales />
  </Page>
)

export default ContactSalesPage
