// extracted by mini-css-extract-plugin
export var container = "ContactSales-module--container--P0-VO";
export var cta_container = "ContactSales-module--cta_container---ayPU";
export var h2 = "ContactSales-module--h2--6qp8Y";
export var h3 = "ContactSales-module--h3--EAAom";
export var h4 = "ContactSales-module--h4--KMkPJ";
export var h5 = "ContactSales-module--h5--vjwpM";
export var p = "ContactSales-module--p--5a9WJ";
export var product_header = "ContactSales-module--product_header--KmEna";
export var phone_number = "ContactSales-module--phone_number--kuYMw";
export var header_container = "ContactSales-module--header_container--zVtbz";
export var phone_container = "ContactSales-module--phone_container--t3jvy";
export var contact_phone_container = "ContactSales-module--contact_phone_container--ko4uU";
export var contact_support = "ContactSales-module--contact_support--63VC3";
export var link_out_props = "ContactSales-module--link_out_props--7Cp0e";
export var content_container = "ContactSales-module--content_container--x3RM-";
export var contact_data_container = "ContactSales-module--contact_data_container--WLNkm";
export var dark_grey_bg = "ContactSales-module--dark_grey_bg--We2lg";
export var bg_container = "ContactSales-module--bg_container--ffO7s";
export var contact_support_section = "ContactSales-module--contact_support_section--fpvgW";
export var request_button = "ContactSales-module--request_button--MhrEm";
export var chat_button = "ContactSales-module--chat_button--o1HWy";
export var shop_button = "ContactSales-module--shop_button--kzUHb";
export var hyper_link = "ContactSales-module--hyper_link--uM73A";
export var grey_bg = "ContactSales-module--grey_bg--htw21";
export var button = "ContactSales-module--button--s9ks+";
export var button_link_out = "ContactSales-module--button_link_out--twQaJ";
export var card_max_height = "ContactSales-module--card_max_height--Vxhnv";