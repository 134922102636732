// @flow
import * as React from 'react'
import cx from 'classnames'

import {Button} from '../../ui-components'

import * as styles from './Contact.module.css'

export type ContactCardProps = {
  question: string,
  solution: string,
  expandSolution?: string,
  button?: boolean,
}

// TODO (mh 2019-11-1): change buttons to be more dynamic incase of future changes

const ButtonLinkOutProps = {
  children: 'view all articles',
  Component: 'a',
  className: styles.button,
  linkOutProps: {
    url: 'https://support.opentrons.com/',
    gtm: {
      action: 'click',
      category: 'b-support',
      label: 'all-articles',
    },
  },
}

export default function ContactCard(props: ContactCardProps) {
  const {question, solution, button, expandSolution} = props
  const [solutionOpen, setSolutionOpen] = React.useState(false)
  return (
    <div
      className={cx(styles.card)}
      onClick={expandSolution ? () => setSolutionOpen(!solutionOpen) : null}
    >
      <div className={styles.card_container}>
        {question}
        {!solutionOpen && solution}
        {solutionOpen && expandSolution}
      </div>
      {button && <Button {...ButtonLinkOutProps} />}
    </div>
  )
}
