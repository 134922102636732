export const PHONE_NUMBER = '+1 (908) 869-8907'
export const PHONE_NUMBER_HREF = 'tel:+1-908-869-8907'

export const CONTACT_SALES_DATA = [
  {
    imgSrc: require('./assets/email.svg').default,
    name: 'Email us',
    description:
      'Send us a message using the form below. Most inquiries receive a response within 24 hours.',
    hasForm: true,
    formId: '7bdc27da-0ca6-4089-993d-bc7c59f62fe7',
    portalId: '5383285',
  },
  {
    imgSrc: require('./assets/chat.svg').default,
    name: 'Live chat',
    button: {
      children: 'chat with us now',
    },
    description:
      'We typically respond within 1 hour during business hours (9:00 AM – 6:00 PM EST, Monday through Friday.)',
  },
  {
    imgSrc: require('./assets/call.svg').default,
    name: 'Call us',
    description: `We're available 9:00 AM – 5:00 PM EST, Monday through Friday.`,
    phoneNumber: PHONE_NUMBER,
  },
]

export const HS_FORM_ID = '7bdc27da-0ca6-4089-993d-bc7c59f62fe7'
export const HS_PORTAL_ID = '5383285'
export const DEMO_PORTAL_ID = '5383285'
export const DEMO_FORM_ID = '2954b45c-c397-49a7-aaed-c9bf48375ab3'

export const protocolLinkOutProps = {
  url: '/protocols/',
  gtm: {
    action: 'click',
    category: 'b-sales',
    label: 'all-articles',
  },
}

export const infoLinkOutProps = {
  url: 'mailto:info@opentrons.com',
  gtm: {
    action: 'click',
    category: 'b-sales',
    label: 'all-articles',
  },
}
