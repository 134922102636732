// @flow
import * as React from 'react'
import cx from 'classnames'
import {Link} from 'gatsby'
import {ContactCard} from '../Contact'
import ScheduleDemoButton from './ScheduleDemoButton'
import {
  Button,
  LinkOut,
  HubspotEmailForm,
  addGtmTags,
} from '../../ui-components'

import {
  PHONE_NUMBER,
  PHONE_NUMBER_HREF,
  HS_FORM_ID,
  HS_PORTAL_ID,
  protocolLinkOutProps,
  infoLinkOutProps,
} from './contact-sales-data'

import EMAIL_IMAGE from './assets/email.svg'
import CHAT_IMAGE from './assets/chat.svg'
import PROTOCOL_IMAGE from './assets/request-protocol.svg'
import PHONE_IMAGE from './assets/call.svg'

import * as styles from './ContactSales.module.css'

const chatButtonProps = {
  children: 'chat with us',
  outline: true,
  onClick: () => {
    if (window.drift && window.drift.api) {
      window.drift.api.startInteraction({interactionId: 325604})
      return
    }

    window.open('http://support.opentrons.com/', '_blank')
  },
}

const ButtonLinkOutProps = {
  children: 'view all articles',
  Component: 'a',
  className: cx(styles.button, styles.button_link_out),
  linkOutProps: {
    url: 'https://support.opentrons.com/',
    gtm: {
      action: 'click',
      category: 'b-sales',
      label: 'all-articles',
    },
  },
}

const shopButtonProps = {
  children: 'shop online',
  Component: 'a',
  className: styles.shop_button,
  linkOutProps: {
    url: 'https://shop.opentrons.com/',
    gtm: {
      action: 'click',
      category: 'b-sales',
      label: 'shop',
    },
  },
}

const CONTACT_SALES_CARD_DATA = [
  {
    question: <p>Do you ship to my country/location?</p>,
    solution: (
      <p>
        Opentrons ships directly and internationally to a shipping address
        chosen by you, anywhere…
      </p>
    ),
    expandSolution: (
      <p>
        Opentrons ships directly and internationally to a shipping address
        chosen by you, anywhere in the world! Once your order ships you will
        receive an email from our fulfillment team confirming this shipment.
        Included in this email will be the tracking number associated with this
        shipment. Robot orders typically arrive ~3-5 business days after being
        shipped. Non-robot orders typically arrive ~1-3 business days after
        being shipped.
      </p>
    ),
  },
  {
    question: <p>Do I need to use your tips?</p>,
    solution: (
      <p>
        Opentrons tips have been extensively tested &amp; verified for use on
        the OT-2…
      </p>
    ),
    expandSolution: (
      <p>
        Opentrons tips have been extensively tested &amp; verified for use on
        the OT-2. Using them will minimize interruptions to your experiment.
        While we very strongly recommend you use Opentrons tips, you can
        technically use any universal bore tips. We have not identified a
        non-Opentrons tip provider that matches the performance of our tips, so
        cannot guarantee or recommend alternative tips.
      </p>
    ),
  },
  {
    question: <p>Are we able to create our own protocols?</p>,
    solution: (
      <p>
        Yes, all options are found{' '}
        <Link className={styles.link_out_props} to={protocolLinkOutProps.url}>
          here
        </Link>
      </p>
    ),
    expandSolution: (
      <p>
        Opentrons tips have been extensively tested &amp; verified for use on
        the OT-2. Using them will minimize interruptions to your experiment.
        While we very strongly recommend you use Opentrons tips, you can
        technically use any universal bore tips. We have not identified a
        non-Opentrons tip provider that matches the performance of our tips, so
        cannot guarantee or recommend alternative tips.
      </p>
    ),
  },
  {
    question: <p>How does installation, service, and training work?</p>,
    solution: <p>We know you want to start automating right…</p>,
    expandSolution: (
      <p>
        We know you want to start automating right away and our onboarding
        process is designed accordingly. Installation and setup are easy and you
        don&#39;t need an Opentrons tech at your lab. After setup you&#39;ll be
        entered into a series of on-boarding calls, emails, and tasks.
        You&#39;ll also have access to our extensive library of support
        articles. If you get stuck, our support team can be reached via video
        call, phone call, chat, or email and are available 40 hours a week,
        during business hours.
      </p>
    ),
  },
  {
    question: <p>What are the dimensions of the OT-2?</p>,
    solution: <p>63cm Width X 57cm Depth X 66cm Height</p>,
    expandSolution: (
      <p>
        63cm Width X 57cm Depth X 66cm Height. The OT-2 was intentionally
        designed to these dimensions to accommodate and fit perfectly onto
        standard sized lab benches.
      </p>
    ),
    button: true,
  },
  {
    question: <p>Do you work with a distributor in my country?</p>,
    solution: (
      <p>
        Contact sales at{' '}
        <LinkOut className={styles.link_out_props} {...infoLinkOutProps}>
          info@opentrons.com
        </LinkOut>{' '}
        for info.
      </p>
    ),
    expandSolution: (
      <p>
        Contact sales at{' '}
        <LinkOut className={styles.link_out_props} {...infoLinkOutProps}>
          info@opentrons.com
        </LinkOut>{' '}
        for info. Opentrons primarily sells directly across the globe in order
        to ensure the most seamless customer experience and ordering process. We
        strategically partner with distributors in countries when necessary. If
        you are interested in learning if Opentrons has a distributor in your
        country due to purchasing restrictions on working with out-of-country
        entities, email info@opentrons.com and we will direct you to the
        appropriate individuals.
      </p>
    ),
  },
]

export default function ContactSales() {
  const HUBSPOT_CATEGORY = 'b-contact-sales'
  const HUBSPOT_LABEL = 'contact1-demo-button'

  React.useEffect(() => {
    addGtmTags(HUBSPOT_CATEGORY, HUBSPOT_LABEL)
  }, [])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.cta_container}>
          <h2 className={styles.h2}>Sales Assistance</h2>
          <h5 className={cx(styles.h5, styles.product_header)}>
            Our prices are 100% transparent.
            <br /> Want to speak to a sales rep? Call us between 9:00-17:00 EST.
          </h5>
          <div className={styles.contact_phone_container}>
            <div className={styles.phone_container}>
              <a href={PHONE_NUMBER_HREF}>
                <img src={PHONE_IMAGE} alt="phone " />
              </a>

              <a href={PHONE_NUMBER_HREF} className={styles.phone_number}>
                {PHONE_NUMBER}
              </a>
            </div>
            <Button {...shopButtonProps} />
          </div>
        </div>
        {/* TODO (mh 2019-11-1): Repeated code would like to
        make this contact support section into a component
        same with contact support */}
        <div className={styles.contact_support}>
          <div className={styles.contact_support_section}>
            <img src={EMAIL_IMAGE} alt="" />
            <h4 className={styles.h4}>Email us</h4>
            <p className={styles.p}>
              Send us a message using the form below. Most inquiries receive a
              response within 24 hours.
            </p>
            <HubspotEmailForm portalId={HS_PORTAL_ID} formId={HS_FORM_ID} />
          </div>
          <div className={styles.contact_support_section}>
            <img src={CHAT_IMAGE} alt="" />
            <h4 className={styles.h4}>Live chat</h4>
            <p className={styles.p}>
              We typically respond within 1 hour during business hours (9:00 AM
              – 6:00 PM EST, Monday through Friday.)
            </p>
            <Button className={styles.chat_button} {...chatButtonProps} />
          </div>
          <div className={styles.contact_support_section}>
            <img src={PROTOCOL_IMAGE} alt="" />
            <h4 className={styles.h4}>Schedule demo</h4>
            <p className={styles.p}>
              Schedule a 20 minute call with our protocol specialists to discuss
              your workflow needs.
            </p>
            <ScheduleDemoButton className={styles.white_button} />
          </div>
        </div>
      </div>
      <div className={styles.grey_bg}>
        <div className={styles.content_container}>
          <div className={styles.header_container}>
            <h3 className={styles.h3}>Sales FAQ</h3>
            <h5 className={styles.h5}>
              Looking for product support? Find answers to common
              <Link className={styles.hyper_link} to="/contact-support">
                support questions here
              </Link>
            </h5>
          </div>
          <div className={styles.contact_data_container}>
            {CONTACT_SALES_CARD_DATA.map((contact, i) => {
              return (
                <ContactCard
                  className={styles.card_max_height}
                  key={i}
                  {...contact}
                />
              )
            })}
            <Button {...ButtonLinkOutProps} />
          </div>
        </div>
      </div>
      <div className={styles.dark_grey_bg}>
        <div className={styles.bg_container}>
          <p>OPENTRONS HEADQUARTERS</p>
          <p>45-18 Ct Square W, Long Island City, NY 11101</p>
        </div>
      </div>
    </>
  )
}
